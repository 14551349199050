<template>
     <vx-card style="padding-left: 10px">
        <h5>Paid To Doctor</h5>

        <vs-table
            ref="table"
            :sst="true"
            :total="totalDocs"
            :data="logData"
            :noDataText="noDataText"
        >

            <template slot="thead">
                <vs-th>Doctor Name</vs-th>
                <vs-th>Year</vs-th>
                <vs-th>Month</vs-th>
                <vs-th>No. of Consolations</vs-th>
                <vs-th>Total Amount</vs-th>
                <vs-th>FC Service Fee</vs-th>
                <vs-th>GST (10%) on Service Fee</vs-th>
                <vs-th>Total Fee Retained by FC</vs-th>
                <vs-th>Net Fee Returned to Doctor</vs-th>
                <vs-th>GST (10%) from Clinic</vs-th>
                <vs-th>Amount Deposited</vs-th>
                <vs-th>Transaction Date</vs-th>
                <vs-th>Transaction Status</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ data[indextr].doctor[0].firstName | capitalize }} {{ data[indextr].doctor[0].lastName | capitalize }}</vs-td>
                <vs-td>{{ data[indextr].year }}</vs-td>
                <vs-td>{{ data[indextr].month }}</vs-td>
                <vs-td>{{ data[indextr].paymentData.totalNumberOfConsultations }}</vs-td>
                <vs-td>{{ data[indextr].paymentData.totalAmount / 100 }} AUD</vs-td>
                <vs-td>{{ data[indextr].paymentData.servicefeeRetainedByFreshClinics / 100 }} AUD</vs-td>
                <vs-td>{{ data[indextr].paymentData.gstOnServiceFee / 100 }} AUD</vs-td>
                <vs-td>{{ data[indextr].paymentData.totalfeeRetainedByFreshClinics / 100 }} AUD</vs-td>
                <vs-td>{{ data[indextr].paymentData.netFeesReturnedToDoctor / 100 }} AUD</vs-td>
                <vs-td>{{ data[indextr].paymentData.gstFromClinics / 100 }} AUD</vs-td>
                <vs-td>{{ data[indextr].paymentData.amountToBeDeposited / 100 }} AUD</vs-td>
                <!-- <vs-td>{{ data[indextr].transactions[0].gstAmount / 100 }} AUD</vs-td>
                <vs-td>{{ data[indextr].transactions[0].grossTotal / 100 }} AUD</vs-td>
                <vs-td>{{ data[indextr].year }}</vs-td>
                <vs-td>{{ data[indextr].month }}</vs-td> -->
                 <vs-td>{{ data[indextr].createdAt | date_formatter }}</vs-td>
                 <vs-td>{{ data[indextr].paidStatus}}</vs-td>
              </vs-tr>
            </template>

        </vs-table>
  </vx-card>
</template>

<script>
export default {
    name: "TransactionLogDoctor",
    props: ["logData"],
    data() {
        return {
            noDataText: "No transaction data available",
            totalDocs: 0,
            awaitingSearch: null,
        }
    }
}
</script>