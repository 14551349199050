<template>
<div>
    <div class="filter mb-5" style="display: flex;">
      <vs-select
            class="select mr-2"
            :placeholder="currentYear"
            autocomplete
            v-model="year"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in yearDropdown"
            />
          </vs-select>

           <vs-select
            class="select  mr-2"
            :placeholder="monthDropdown[currentMonth]"
            autocomplete
            v-model="month"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in monthDropdown"
            />
          </vs-select>

           <vs-button style="padding: 10px;" @click="filterLog()">Filter</vs-button>
    </div>

    <vx-card style="padding-left: 10px">
        <h5>Paid By Clinic</h5>
        <vs-table
            ref="table"
            :sst="true"
            :total="totalDocs"
            :data="clinicLogs"
            :noDataText="noDataText"
        >

            <template slot="thead">
                <vs-th>Clinic Name</vs-th>
                 <vs-th>Year</vs-th>
                <vs-th>Month</vs-th>
                <vs-th>Net Amount</vs-th>
                <vs-th>GST (10%)</vs-th>
                <vs-th>Total</vs-th>
                <vs-th>Transaction Date</vs-th>
                <vs-th>Transaction Status</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ data[indextr].clinic[0].clinicName}}</vs-td>
                 <vs-td>{{ data[indextr].year }}</vs-td>
                <vs-td>{{ data[indextr].month }}</vs-td>
                <vs-td>{{ data[indextr].transactions[0].netTotal / 100 }} AUD</vs-td>
                <vs-td>{{ data[indextr].transactions[0].gstAmount / 100 }} AUD</vs-td>
                <vs-td>{{ data[indextr].transactions[0].grossTotal / 100 }} AUD</vs-td>
                 <vs-td>{{ data[indextr].createdAt | date_formatter }}</vs-td>
                 <vs-td>{{ data[indextr].paidStatus}}</vs-td>
              </vs-tr>
            </template>
        </vs-table>
  </vx-card>

  <vs-divider class="my-6"></vs-divider>

  <TransactionLogDoctor :logData="doctorLogs" />
</div>
  
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import TransactionLogDoctor from "./TransactionLogDoctor";

export default {
  components: {
    TransactionLogDoctor
  },
  data() {
    return {
      noDataText: "No transaction data available",
      rerenderKey: 0,
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      serverResponded: false,
      transactionData: [],
      awaitingSearch: null,
      year: moment(new Date())
      .startOf("month")
      .format("YYYY"),
      month: moment(new Date()).format("MMM"),
      clinicLogs: [],
      doctorLogs: [],
      yearDropdown: [],
       monthDropdown: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      currentMonth: new Date().getMonth(),
       currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    ...mapActions("log", ["fetchTransactionLog"]),
    fetchLog(search = ""){
      this.$vs.loading();
      this.fetchTransactionLog({year: this.year, month: this.month, search})
      .then(result => {
        this.$vs.loading.close();
        this.clinicLogs = result.data.data.clinicLogs;
        this.doctorLogs = result.data.data.doctorLogs;
      }).catch(err => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: err.data.message,
          color: "danger",
        });
      });
    },
    getYearDropdown() {
      for (let i = 2020; i <= this.currentYear; i++) {
        this.yearDropdown.push(i);
      }
    },
    filterLog(){
      this.fetchLog();
    }
  },
  async created() {
    this.getYearDropdown();
    this.fetchLog();
  }
};
</script>

<style scoped></style>
